import React, { FC } from "react";
import "./favoriteText.css";

interface FavoriteTextInt {
  favorite: {
    date: string;
    name: string;
    text: string;
  };
}

const FavoriteText: FC<FavoriteTextInt> = ({ favorite }) => {
  return (
    <div className="favoriteText-wrapper">
      <div className="favoriteText-date">
        <p>{favorite.date}</p>
        <div className="vert-line" />
      </div>
      <div className="favoriteText-content">
        <h1>{favorite.name}</h1>
        <p>{favorite.text}</p>
      </div>
    </div>
  );
};

export default FavoriteText;
