import React, { FC } from "react";
import "./favorites.css";
import { FavoriteText } from "../../components";
import favorite1 from "../../assets/favorite1.webp";
import two from "../../assets/two.webp";
import three from "../../assets/three.webp";
import { FAVORITE_DATA } from "./favoritesData";

interface FavoritesInt {}

const Favorites: FC<FavoritesInt> = () => {
  return (
    <div className="favorites-wrapper">
      <div className="favorites-content section-padding container">
        <div className="favorites-main">
          <h1 className="title">My Favorites</h1>
          <div className="favorite-card">
            <div className="favorite-art">
              <img src={favorite1} alt="" />
            </div>
            <FavoriteText favorite={FAVORITE_DATA[0]} />
          </div>
          <div className="favorite-card">
            <FavoriteText favorite={FAVORITE_DATA[1]} />
            <div className="favorite-art">
              <img src={two} alt="" />
            </div>
          </div>
          <div className="favorite-card">
            <div className="favorite-art">
              <img src={three} alt="" />
            </div>
            <FavoriteText favorite={FAVORITE_DATA[2]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites;
