import first from "../../assets/first.webp";
import second from "../../assets/second.webp";
import third from "../../assets/third.webp";
import fourth from "../../assets/fourth.webp";
import fifth from "../../assets/hero.webp";
import sixths from "../../assets/sixths.webp";
import seven from "../../assets/seven.webp";
import eight from "../../assets/eight.webp";

const CAROUSEL_DATA = [
  {
    img: first,
    title: "Mermaids Blush",
    text: "7 sep 2020",
    parag:
      "Mermaids Blush captures the essence of Mermaids and the beauty of their underwater world.",
  },
  {
    img: second,
    title: "Snow Queen",
    text: "22 aug 2020",
    parag:
      "Snow Queen captures the essence of winter, with its icy blue and white hues, and fluid forms creating an illusion of falling snow.",
  },
  {
    img: third,
    title: "Hurtling Space",
    text: "21 jan 2022",
    parag:
      "Hurtling Space features an explosive blend of colors, evoking the sense of movement, depth and the vastness of space.",
  },
  {
    img: fourth,
    title: "Moody Blues",
    text: "12 mar 2022",
    parag:
      "Moody Blues features a dramatic blend of blues and grays, creating a moody and contemplative atmosphere.",
  },
  {
    img: fifth,
    title: "Gold Cells",
    text: "10 nov 2022",
    parag:
      "Gold Cells captures the essence of gold through its glittering blend of colors.",
  },
  {
    img: sixths,
    title: "Dreamy, Silky Vibe",
    text: "15 nov 2022",
    parag:
      "Dreamy Silky Vibe captures the essence of calmness and serenity, creating an inviting and soothing atmosphere.",
  },
  {
    img: seven,
    title: "Vinyl Art",
    text: "10 nov 2022",
    parag:
      "Vinyl Art captures the essence of music and its vibrant energy. With the red hues adding an extra striking touch to the composition.",
  },
  {
    img: eight,
    title: "Blue Mosaic",
    text: "22 jun 2022",
    parag:
      "Blue Mosaic captures the essence of serenity and wonder with its unique blend of blue hues, shapes and textures.",
  },
];

export { CAROUSEL_DATA };
