import React, { FC } from "react";
import "./footer.css";

interface FooterInt {}

const MenuMenu = () => (
  <>
    <p>
      <a href="/">Home</a>
    </p>
    <p>
      <a href="/Collection">Collection</a>
    </p>
    <p>
      <a href="/contact">Contact</a>
    </p>
  </>
);

const Footer: FC<FooterInt> = () => {
  return (
    <div className="Footer-wrapper">
      <div className="Footer-Content container section-padding">
        <div className="footer-menu">
          <div className="footer-menu-links">
            <MenuMenu />
          </div>
          <div className="footer-totop">
            <button onClick={() => window.scrollTo(0, 0)}>
              Back to top of the page
            </button>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-socials">
            <a
              href="https://www.instagram.com/kcfluidart/?next=%2Fkcfluidart%2F"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
            >
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/KCAcrylicArt/"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#fff"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
              </svg>
            </a>
            <a href="/" target="_blank" rel="noreferrer" aria-label="Email">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
              >
                <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm-.141-6.333c.63 0 1.141-.512 1.141-1.142v-9.05c0-.63-.511-1.142-1.141-1.142h-13.718c-.63 0-1.141.512-1.141 1.142v9.05c0 .63.511 1.142 1.141 1.142h13.718zm-6.859-4.058l-6.228-4.321-.014 7.712h12.457v-7.712l-6.215 4.321zm5.913-6.609c-1.745 1.215-5.913 4.153-5.913 4.153l-5.947-4.153h11.86z" />
              </svg>
            </a>
          </div>
          <p>
            Copyright 2023 by Dor Tagger. All rights reserved. The content on
            this website, including text and images, is protected by copyright
            laws. Any unauthorized use or reproduction of this material is
            prohibited. If you have any questions or concerns, please contact
            us.
          </p>

          <ul>
            <li>CatherineConlon@NotRealEmail.com</li>
            <li>10 Ring of kerry, Kerry, Ireland</li>
            <li>Tel: 012443223</li>
          </ul>

          <div>
            <a href="/">Terms & Conditions</a>
            <a href="/">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
