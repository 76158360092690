import React, { FC } from "react";
import "./collectionCon.css";
import { CollectionItem } from "../../components";
import { COLLECTIONCON_DATA } from "./collectionConData";

interface CollectionConInt {}

const CollectionCon: FC<CollectionConInt> = () => {
  return (
    <div className="collection-con-wrapper section-padding container">
      {COLLECTIONCON_DATA.map((item, index) => (
        <CollectionItem item={item} key={index} />
      ))}
    </div>
  );
};

export default CollectionCon;
