import React, { FC } from "react";
import "./exhibition.css";

interface ExhibitionInt {
  exhibition: {
    img: string;
    title: string;
    text: string;
    parag: string;
  };

  numberOfCards: number;
}

const Exhibition: FC<ExhibitionInt> = ({ exhibition, numberOfCards }) => {
  const Percent = 100 / numberOfCards;
  const ITEM_WIDTH = Percent.toString();

  return (
    <div className="exhibition-wrapper" style={{ width: `${ITEM_WIDTH}%` }}>
      <div className="exhibition-nail" />
      <div className="exhibition-line" />
      <div className="exhibition-artwork">
        <img src={exhibition.img} alt={exhibition.title} />
        <div className="exhibition-hidden">
          <div className="vert-line" />
          <p>{exhibition.parag}</p>
        </div>
      </div>
      <h2>{exhibition.title}</h2>
      <p className="upper">{exhibition.text}</p>
    </div>
  );
};

export default Exhibition;
