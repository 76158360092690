import React, { FC } from "react";
import "./about.css";
import hero from "../../assets/test.png";

interface AboutInt {}

const About: FC<AboutInt> = () => {
  return (
    <div className="about-wrapper">
      <div className="about-container section-padding container">
        <div className="about-title">
          <h1 className="title">About Me & My Paintings</h1>
          <div className="horz-line" />
        </div>
        <div className="about-content">
          <div className="about-decorated-text">
            <p>
              ​As an acrylic pouring artist, I experiment with different
              techniques and materials to create unique and mesmerizing
              paintings. I am particularly drawn to bold and vibrant colors and
              enjoy experimenting with different textures and surfaces. I share
              my work through social media and online platforms and enjoy
              teaching others through workshops and classes. I am driven by a
              desire to create one-of-a-kind pieces.
            </p>
          </div>
          <div className="about-explanation">
            <p>
              Acrylic pouring is a unique and dynamic art form that involves
              mixing fluid acrylics with a pouring medium and applying it to a
              surface. As an acrylic pouring artist, my work is focused on
              exploring the possibilities of this medium and experimenting with
              different techniques and materials.
            </p>
            <p>
              One of the most exciting aspects of acrylic pouring for me is the
              unpredictability of the final product. Each painting is unique and
              cannot be replicated exactly. This adds to the excitement of the
              creative process and allows me to push the boundaries of what is
              possible with this technique. I also enjoy experimenting with
              different pouring mediums and additives, as they can greatly
              affect the final result.
            </p>
            <p>
              In my work, I am particularly drawn to bold and vibrant colors. I
              enjoy using a wide range of hues and shades to create depth and
              interest in my paintings. I also like to experiment with different
              textures, such as using a palette knife to create a more textured
              surface. In addition to canvases, I also enjoy working on other
              surfaces such as wood and metal to give my paintings a different
              dimension.
            </p>
            <p>
              I have not had the opportunity to exhibit my work in galleries,
              but I do share my work through social media and online platforms.
              I enjoy sharing my process and techniques with other artists
              through workshops and classes. Teaching others about acrylic
              pouring has been a rewarding experience for me and I enjoy seeing
              others find success and enjoyment in this art form.
            </p>
            <p>
              In addition to acrylic pouring, I am also interested in other
              forms of art and enjoy experimenting with different mediums to
              find new ways of expressing myself. I believe that being open to
              different forms of art can only enrich my acrylic pouring work.
            </p>
            <p>
              In conclusion, acrylic pouring is a dynamic and exciting art form
              that allows for endless possibilities. As an acrylic pouring
              artist, I am constantly pushing the boundaries of what is possible
              with this technique and experimenting with different colors,
              textures, and forms. My work is driven by a love for bold and
              vibrant colors, and a desire to create unique and mesmerizing
              paintings that can never be replicated.
            </p>
          </div>
          <div className="about-art">
            <img src={hero} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
