import React, { FC } from "react";
import "./studio.css";
import studio from "../../assets/studio.webp";

interface StudioInt {}

const Studio: FC<StudioInt> = () => {
  return (
    <div className="studio-wrapper">
      <div className="studio-content section-padding container">
        <div className="studio-para">
          <h1 className="title">My Studio</h1>
          <p>
            My acrylic pouring studio is a sanctuary for my creativity. It is
            equipped with everything I need to create my dynamic and unique
            acrylic pouring paintings. The natural light and open space allows
            me to work on large pieces and move around freely.
          </p>
          <p>
            The studio is designed to be a peaceful and calming space, with a
            minimalist aesthetic that allows me to focus on my work and easily
            access my materials and tools. It is where I experiment and take
            risks with my art, pushing the boundaries of my creativity.
          </p>
          <a href="/contact" className="buttonStyle">
            Contact
          </a>
        </div>
        <div className="studio-image">
          <img src={studio} alt="studio" />
        </div>
      </div>
    </div>
  );
};

export default Studio;
