import one from "../../assets/eight.webp";
import two from "../../assets/favorite1.webp";
import three from "../../assets/third.webp";
import four from "../../assets/three.webp";
import five from "../../assets/second.webp";
import six from "../../assets/hero.webp";
import seven from "../../assets/seven.webp";
import eight from "../../assets/first.webp";
import nine from "../../assets/two.webp";
import ten from "../../assets/fourth.webp";

const COLLECTIONCON_DATA = [
  {
    img: one,
    desc: "Blue Mosaic",
  },
  { img: two, desc: "Sandy Shores" },
  { img: three, desc: "Hurtling Space" },
  { img: four, desc: "River Blue" },
  { img: five, desc: "Snow Queen" },
  { img: six, desc: "Gold Cells" },
  { img: seven, desc: "Vinyl Art" },
  { img: eight, desc: "Mermaids Blush" },
  { img: nine, desc: "Riverbed Reflections" },
  { img: ten, desc: "Moody Blues" },
];

export { COLLECTIONCON_DATA };
