import React, { FC, useEffect } from "react";
import { CollectionCon } from "../../containers";
import "./collection.css";

interface CollectionInt {
  setOn: any;
}

const Collection: FC<CollectionInt> = ({ setOn }) => {
  useEffect(() => {
    setOn("co");
    document.title = "Catherine Conlon - Collection" || "";
  }, [setOn]);
  return (
    <div className="collection-wrapper">
      <h2>Explore The Collection</h2>
      <h1 className="title">Collection</h1>
      <div className="horz-line" />
      <CollectionCon />
    </div>
  );
};

export default Collection;
