import React, { FC } from "react";
import "./hero.css";
import HeroImg from "../../assets/three.webp";

interface HeroInt {
  offsetY: number;
}

const Hero: FC<HeroInt> = ({ offsetY }) => {
  return (
    <div
      className="hero-wrapper"
      style={
        {
          "--paralax-transform": `translateY(${offsetY * 0.075}px)`,
        } as React.CSSProperties
      }
      id="hero"
    >
      <div className="hero-container section-padding container">
        <div className="paralax">
          <div className="hero-title">
            <h1>
              Catherine Conlon,
              <br />
              Paintings
            </h1>
            <div className="hero-title-explanation">
              <h2>February 27, 2018</h2>
              <p className="small-explanation">
                Blues and greens swirl and dance together, creating a
                mesmerizing display of movement and color.
              </p>
              <p>
                Brings a calming and peaceful atmosphere to any room it is
                displayed in.
              </p>

              {/* <h3>River Blue</h3> */}
            </div>
          </div>
        </div>
        <div className="hero-img">
          <img src={HeroImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
