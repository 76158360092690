import eight from "../../assets/eight.webp";
import three from "../../assets/three.webp";
import first from "../../assets/first.webp";
import sixths from "../../assets/sixths.webp";

const COLLECTION_DATA = [
  { img: eight, desc: "Blue Mosaic" },
  { img: three, desc: "River Blue" },
  { img: first, desc: "Sunset" },
  { img: sixths, desc: "Ethereal Dream" },
];

export { COLLECTION_DATA };
