import React, { FC } from "react";
import "./exhibitions.css";
import { Carousel } from "../../components";

interface ExhibitionsInt {}

const Exhibitions: FC<ExhibitionsInt> = () => {
  return (
    <div className="exhibitions-wrapper">
      <div className="exhibitions-content section-padding container">
        <h1 className="title">Exhibitions</h1>
        <div className="horz-line" />
        <Carousel />
      </div>
    </div>
  );
};

export default Exhibitions;
