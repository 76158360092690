import React, { FC, useState, useRef, useEffect } from "react";
import "./menu.css";

interface MenuInt {
  scrolling: boolean;
  on: any;
}

const MenuMenu = () => (
  <>
    <p>
      <a href="/">Home</a>
    </p>
    <p>
      <a href="/collection">Collection</a>
    </p>
    <p>
      <a href="/contact">Contact</a>
    </p>
  </>
);

const Menu: FC<MenuInt> = ({ scrolling, on }) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [closeMenuNow, setCloseMenu] = useState(Boolean);

  const handleClose = () => {
    setCloseMenu(true);
    setTimeout(() => {
      setToggleMenu(false);
      setCloseMenu(false);
    }, 500);
  };

  const openMenu = () => {
    setToggleMenu(true);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (toggleMenu && !ref.current?.contains(e.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggleMenu]);

  return (
    <div
      id="navbar"
      className={`Menu-Wrapping  ${scrolling ? "scrolling" : ""}`}
    >
      <div className="Menu-Content container section-padding">
        <a href="/">
          <h1>Catherine Conlon</h1>
        </a>
        <div className={`Menu-Menu-Wrapper ${on}`}>
          <MenuMenu />
        </div>
        <div className="Mobile-Menu">
          <svg
            onClick={openMenu}
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#32353b"
            className="open-svg"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>

          {toggleMenu ? (
            <div
              className={`Mobile-Menu-Open ${on} slide-in-right ${
                closeMenuNow ? "banana" : null
              }`}
              ref={ref}
            >
              <MenuMenu />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
                onClick={handleClose}
                className="Close-Mobile-Menu"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Menu;
