import React, { FC, useEffect } from "react";
import { ContactSection } from "../../containers";

interface ContactInt {
  setOn: any;
}

const Contact: FC<ContactInt> = ({ setOn }) => {
  useEffect(() => {
    setOn("c");
    document.title = "Catherine Conlon - Contact" || "";
  }, [setOn]);

  return (
    <div>
      <ContactSection />
    </div>
  );
};

export default Contact;
