import React, { FC, useState, useEffect } from "react";
import "./carousel.css";
import { Exhibition } from "../";
import { CAROUSEL_DATA } from "./carouselData";

interface CarouselInt {}

const Carousel: FC<CarouselInt> = () => {
  const [numberOfCards, setNumberOfCards] = useState(4);
  const TRANSFORM_AMOUNT = 100;

  const length =
    (Math.ceil(CAROUSEL_DATA.length / numberOfCards) - 1) * TRANSFORM_AMOUNT;
  const [transform, setTransform] = useState<number>(0);
  const up = () => {
    if (transform === length * -1) {
      setTransform(0);
    } else {
      setTransform(transform - TRANSFORM_AMOUNT);
    }
  };

  const down = () => {
    if (transform === 0) {
      setTransform(length * -1);
    } else {
      setTransform(transform + TRANSFORM_AMOUNT);
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1300) {
        setNumberOfCards(4);
      }
      if (window.innerWidth < 1300 && window.innerWidth > 1000) {
        setNumberOfCards(3);
      }
      if (window.innerWidth < 1000 && window.innerWidth > 550) {
        setNumberOfCards(2);
      }
      if (window.innerWidth < 550) {
        setNumberOfCards(1);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [setNumberOfCards]);

  return (
    <div className="carousel-outer">
      <div
        className="carousel-wrapper"
        style={{ transform: `translateX(${transform}%)` }}
      >
        {CAROUSEL_DATA.map((exhibition, index) => (
          <Exhibition
            exhibition={exhibition}
            key={index}
            numberOfCards={numberOfCards}
          />
        ))}
      </div>
      <div className="carousel-buttons">
        <button onClick={down} aria-label="Carousel Right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>
        </button>
        <button onClick={up} aria-label="Carousel Left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
