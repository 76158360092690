import React, { FC } from "react";
import "./collectionItem.css";

interface CollectionItemInt {
  item: {
    img: any;
    desc: string;
  };
}

const CollectionItem: FC<CollectionItemInt> = ({ item }) => {
  return (
    <div className="collectionItem-wrapper">
      <div className="collectionItemInner">
        <img src={item.img} alt={item.desc} />
        <div className="collectionItem-hidden">
          <h1>{item.desc}</h1>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
