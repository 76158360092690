import React, { FC, useEffect } from "react";
import {
  Hero,
  Exhibitions,
  About,
  Favorites,
  Studio,
  CollectionHome,
} from "../../containers";

interface HomeInt {
  offsetY: number;
  setOn: any;
}

const Home: FC<HomeInt> = ({ offsetY, setOn }) => {
  useEffect(() => {
    setOn("h");
    document.title = "Catherine Conlon - Home" || "";
  }, [setOn]);

  return (
    <div>
      <Hero offsetY={offsetY} />
      <Exhibitions />
      <About />
      <Favorites />
      <Studio />
      <CollectionHome />
    </div>
  );
};

export default Home;
