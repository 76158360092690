const FAVORITE_DATA = [
  {
    date: "August 10",
    name: "Sandy Shores",
    text: "Sandy Shores captures the essence of the beach with its blend of brown and blue colors. The fluid forms and organic shapes create an impression of the sandy shore, resembling the natural flow of the beach. The painting evokes a sense of serenity and tranquility.",
  },
  {
    date: "July 12",
    name: "Riverbed Reflections",
    text: "Riverbed Reflections captures the essence of a river with its blend of brown and blue colors. The fluid forms and organic shapes create an impression of the riverbed, resembling the flow of water. The painting evokes a sense of serenity and tranquility, and the reflections on the water surface adds a mesmerizing effect to the composition.",
  },
  {
    date: "March 21",
    name: "River Blue",
    text: "River Blue evokes an ethereal and otherworldly atmosphere, inviting the viewer to a meditative state. The dynamic blend of blue hues, shapes, and textures creates an impression of the sea and the unique shades of blue gives it a striking appearance.",
  },
];

export { FAVORITE_DATA };
