import React, { FC, useState, useRef } from "react";
import "./contactSection.css";
import emailjs from "@emailjs/browser";

interface ContactSectionInt {}

const ContactSection: FC<ContactSectionInt> = () => {
  const [openEnv, setOpenEnv] = useState(false);
  const [email, setEmail] = useState(true);
  const [buttonText, setButtonText] = useState("Send");
  const [titleText, setTitleText] = useState("Love to hear from you,");
  const [secondTitleText, setSecondTitleText] = useState("Get in touch 👋");
  const form = useRef<any>();
  const sendEmail = (e: any) => {
    e.preventDefault();
    setButtonText("Sending...");
    emailjs
      .sendForm(
        "service_298harr",
        "template_hxhx4xw",
        form.current,
        "i-053KC2B-IomQu89"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail(false);
          setTitleText("Thank you for your message,");
          setSecondTitleText("Talk soon! 👋");
        },
        (error) => {
          console.log(error.text);
          setButtonText("Error - Send Again");
        }
      );
  };

  const checkEnv = () => {
    if (openEnv) {
      setOpenEnv(true);
    } else if (!openEnv) {
      setOpenEnv(true);
    }
  };
  return (
    <div>
      <div className="section-padding container contact-wrapper">
        <div className="contact-left">
          <div className="horiz-line" />
          <div
            onClick={checkEnv}
            className={`contact-info ${openEnv ? "open-env" : null}`}
          >
            <h2>Email</h2>
            <p>KCFLuidArt@NotRealEmail.com</p>
            <div className="vert-line" />
            <h2>Tel</h2>
            <p>+012443223</p>
          </div>
        </div>
        <div className="contact-content">
          <div className="contact-title">
            <h1>
              {titleText} <br /> {secondTitleText}
            </h1>
          </div>
          <div className="contact-right">
            {email ? (
              <form action="submit" ref={form} onSubmit={sendEmail}>
                <div className="input-section">
                  <div className="input-area">
                    <label htmlFor="name">Your name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="John Dough"
                      required
                    />
                  </div>
                  <div className="input-area">
                    <label htmlFor="email">Your email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="my@email.com"
                      required
                    />
                  </div>
                  <div className="input-area">
                    <label htmlFor="phone">Your phone</label>
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder="+1 234 567 890"
                    />
                  </div>
                  <div className="input-area">
                    <label htmlFor="reason">Subject</label>
                    <input
                      type="text"
                      id="reason"
                      name="reason"
                      placeholder="Intrested in purchasing a painting"
                    />
                  </div>
                </div>
                <div className="input-area text-area-message">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    placeholder="Type your message :)"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="buttonStyle">
                  {buttonText}
                </button>
              </form>
            ) : null}
            {!email ? (
              <div>
                <p>
                  We will get back to you as soon as we can. If you don't hear
                  from us shortly, please check the information on the left or
                  bottom of our website and reach out to us through those
                  channels. Talk to you soon!
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
