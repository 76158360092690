import React, { useState, useEffect } from "react";
import "./App.css";
import { Menu } from "./components";
import { Footer } from "./containers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Collection from "./pages/collection/Collection";

function App() {
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [offsetY, setOffsetY] = useState<number>(0);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      setScrolling(false);
    }

    if (currentScroll > 0) {
      setScrolling(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  type menu = "h" | "c" | "co";
  const [on, setOn] = useState<menu>("h");

  return (
    <div className="App">
      <header className="App-header">
        <Menu scrolling={scrolling} on={on} />
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home offsetY={offsetY} setOn={setOn} />} />
          <Route path="/contact" element={<Contact setOn={setOn} />} />
          <Route path="/collection" element={<Collection setOn={setOn} />} />
        </Routes>
      </BrowserRouter>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
