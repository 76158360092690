import React, { FC } from "react";
import "./collectionHome.css";
import { CollectionItem } from "../../components";
import { COLLECTION_DATA } from "./collectionHomeData";

interface CollectionHomeInt {}

const CollectionHome: FC<CollectionHomeInt> = () => {
  return (
    <div className="collectionHome-wrapper">
      <div className="collectionHome-content section-padding container">
        <h1 className="title">Explore The Collection</h1>
        <div className="collectionHome-images">
          {COLLECTION_DATA.map((item, index) => (
            <CollectionItem item={item} key={index} />
          ))}
        </div>
        <a href="/collection" className="buttonStyle">
          View full collection{" "}
          <svg viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default CollectionHome;
